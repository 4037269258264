<template>
  <div>
    <el-dialog
      title="制程导入"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
      <el-card class="grid-card" shadow="hover">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold">制程JSON文件导入</span>
        </div>
        <el-row :gutter="10" style="margin-left: 10px">
          <el-col :span="24">
            <el-upload
              class="dataImport"
              ref="dataImport"
              :action="importApiUrl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              :limit="1"
              accept=".json"
              :data="importData"
              :on-exceed="handleExceed"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-change="handleChange"
              :auto-upload="false"
            >
              <el-button slot="trigger" icon="el-icon-folder-add" size="small"
                >选择文件</el-button
              >
              <div slot="tip" class="el-upload__tip">
                请上传JSON文件, 且不超过80MB
              </div>
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                title="确定要导入文件吗？"
                @confirm="submitDataImport()"
              >
                <el-button
                  style="margin-left: 10px"
                  icon="el-icon-upload"
                  size="small"
                  type="primary"
                  slot="reference"
                  >开始导入</el-button
                >
              </el-popconfirm>
            </el-upload>
          </el-col>
        </el-row>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
    <el-row :gutter="20" class="page-box task-list-page">
      <el-col>
        <Breadcrumb
          :home="false"
          :breadcrumbs="[
            {
              label: '制程管理',
              to: '/workflow',
            },
          ]"
        />
      </el-col>
      <el-col>
        <el-card class="grid-card" shadow="never">
          <SchemaTable
            ref="schematableref"
            :schema="schema"
            :model="dataModel"
            :search="search"
            :filters="filters"
            :queryConf="queryConf"
            :hasReset="hasReset"
            :hasAction="hasAction"
            queryLayout="prependBtnSearch"
          >
            <template #queryBtns="{ row }">
              <!-- 用 el-link制作按钮，添加 action-link 的 class 增加间距 -->
              <el-button
                type="primary"
                plain
                style="float: right; margin-right: 10px"
                icon="el-icon-upload2"
                @click="importAuto(row)"
              >
                导入
              </el-button>
            </template>
            <template #actionBtns="{ row }">
              <!-- <el-link
                type="primary"
                class="action-link"
                @click="goProcess(row)"
              >
                管理
              </el-link> -->
              <el-link
                type="primary"
                class="action-link"
                @click="exportWf(row)"
              >
                导出
              </el-link>
              <el-link
                type="primary"
                class="action-link"
                @click="goWorkflowDetail(row)"
              >
                详情
              </el-link>
            </template>
          </SchemaTable>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./workflow.schema.json";
import { uris, attachId } from "./../../api/uri";
import axios from "axios";
import Breadcrumb from "enn-breadcrumb";
export default {
  components: {
    SchemaTable,
    Breadcrumb,
  },
  data() {
    return {
      workflowId: "",
      importApiUrl: "",
      dialogVisible: false,
      importData: {},
      fileList: [],
      editableTabsValue: "1",
      editableTabs: [
        {
          title: "制程管理",
          name: "1",
          id: 0,
        },
      ],
      tabIndex: 1,
      hasAction: true,
      search: "请输入制程名称",
      filters: ["envType", "productId"],
      schema,
      hasReset: false,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.workflow}`,
        getListMap: (item) => {
          item.productName = item.product ? item.product.name : "";
          item.createdTime = +new Date(item.created_at);
          return item;
        },
        getApi: `${uris.workflow}${attachId}`,
        getMap(res) {
          res.envType = res.envType + "";
          return res;
        },
        deleteApi: `${uris.workflow}${attachId}`,
        createApi: uris.workflow,
        updateApi: `${uris.workflow}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    importAuto() {
      this.importApiUrl =
        axios.defaults.baseURL +
        "/api/v1/workflow/import?authorization=" +
        localStorage.getItem("token");
      this.dialogVisible = true;
    },
    async submitDataImport() {
      if (this.importData.md5) {
        this.$refs.dataImport.submit();
      } else {
        this.$message.error("请选择JSON文件！");
      }
    },

    // 导入文件相关方法（上传文件）
    handleRemove(file, fileList) {
      this.importData.md5 = "";
      console.log(file, fileList);
    },

    handlePreview(file) {
      console.log(file);
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    async handleSuccess(response, file, fileList) {
      if (!response.code) {
        this.importData.md5 = "";
        this.$message({
          message: "制程导入成功",
          type: "success",
        });
        await this.handleClose();
      } else {
        this.$message({
          message: response.message,
          type: "warning",
        });
        await this.handleClose();
      }
    },

    async handleChange(file) {
      this.importData.size = file.size;
      const that = this;
      const fileReader = new FileReader();
      const dataFile = file.raw;
      // const spark = new SparkMD5.ArrayBuffer();
      fileReader.readAsArrayBuffer(dataFile);
      //异步执行函数
      fileReader.onload = function (e) {
        // spark.append(e.target.result);
        // const md5 = spark.end();
        // console.log(md5);
        //此处this指向发生改变，需提前声明const _this = this
        // that.importData.md5 = md5; //此处是将文件的md5值放入dataImport中
        that.importData.md5 = "none"; // 复制为了后续检测
      };
    },

    async handleClose() {
      this.$refs.schematableref.onSearch();
      this.$refs.dataImport.clearFiles();
      this.dialogVisible = false;
    },

    async handleError(err, file, fileList) {
      if (err.status == 413) {
        this.$notify.error({
          title: "错误",
          message: "文件大小超出限制",
        });
      } else {
        this.$notify.error({
          title: "错误",
          message: "不支持此文件格式上传",
        });
      }
    },
    exportWf(row) {
      this.workflowId = row.id;
      axios
        .post(`${uris.workflowExport}`, {
          workflowId: this.workflowId,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          if (res.data.result && res.data.result.fileUrl) {
            window.open(res.data.result.fileUrl);
          }
        });
    },
    goProcess(row) {
      this.workflowId = row.id; 
      this.$router.push({
        name: "process",
        query: { workflowId: row.id, groupId: row.groupId },
      });
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    goWorkflowDetail(row) {
      this.workflowId = row.id;
      this.$router.push({
        name: "workflow-detail",
        query: { workflowId: row.id, groupId: row.groupId },
      });
    },
  },
};
</script>

<style scoped>
</style>