var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"制程导入","visible":_vm.dialogVisible,"close-on-click-modal":false,"close-on-press-escape":false,"width":"600px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-card',{staticClass:"grid-card",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("制程JSON文件导入")])]),_c('el-row',{staticStyle:{"margin-left":"10px"},attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":24}},[_c('el-upload',{ref:"dataImport",staticClass:"dataImport",attrs:{"action":_vm.importApiUrl,"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"limit":1,"accept":".json","data":_vm.importData,"on-exceed":_vm.handleExceed,"on-success":_vm.handleSuccess,"on-error":_vm.handleError,"on-change":_vm.handleChange,"auto-upload":false}},[_c('el-button',{attrs:{"slot":"trigger","icon":"el-icon-folder-add","size":"small"},slot:"trigger"},[_vm._v("选择文件")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 请上传JSON文件, 且不超过80MB ")]),_c('el-popconfirm',{attrs:{"icon":"el-icon-info","icon-color":"red","title":"确定要导入文件吗？"},on:{"confirm":function($event){return _vm.submitDataImport()}}},[_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"slot":"reference","icon":"el-icon-upload","size":"small","type":"primary"},slot:"reference"},[_vm._v("开始导入")])],1)],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleClose()}}},[_vm._v("关 闭")])],1)],1),_c('el-row',{staticClass:"page-box task-list-page",attrs:{"gutter":20}},[_c('el-col',[_c('Breadcrumb',{attrs:{"home":false,"breadcrumbs":[
          {
            label: '制程管理',
            to: '/workflow',
          } ]}})],1),_c('el-col',[_c('el-card',{staticClass:"grid-card",attrs:{"shadow":"never"}},[_c('SchemaTable',{ref:"schematableref",attrs:{"schema":_vm.schema,"model":_vm.dataModel,"search":_vm.search,"filters":_vm.filters,"queryConf":_vm.queryConf,"hasReset":_vm.hasReset,"hasAction":_vm.hasAction,"queryLayout":"prependBtnSearch"},scopedSlots:_vm._u([{key:"queryBtns",fn:function(ref){
        var row = ref.row;
return [_c('el-button',{staticStyle:{"float":"right","margin-right":"10px"},attrs:{"type":"primary","plain":"","icon":"el-icon-upload2"},on:{"click":function($event){return _vm.importAuto(row)}}},[_vm._v(" 导入 ")])]}},{key:"actionBtns",fn:function(ref){
        var row = ref.row;
return [_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.exportWf(row)}}},[_vm._v(" 导出 ")]),_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goWorkflowDetail(row)}}},[_vm._v(" 详情 ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }